// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js": () => import("./../node_modules/gatsby-source-prismic-graphql/components/PreviewPage.js" /* webpackChunkName: "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js" */),
  "component---src-templates-lesson-js": () => import("./../src/templates/lesson.js" /* webpackChunkName: "component---src-templates-lesson-js" */),
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-glossary-js": () => import("./../src/pages/glossary.js" /* webpackChunkName: "component---src-pages-glossary-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-subscriptions-check-your-email-js": () => import("./../src/pages/subscriptions/check-your-email.js" /* webpackChunkName: "component---src-pages-subscriptions-check-your-email-js" */),
  "component---src-pages-subscriptions-welcome-js": () => import("./../src/pages/subscriptions/welcome.js" /* webpackChunkName: "component---src-pages-subscriptions-welcome-js" */)
}

