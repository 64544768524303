exports.linkResolver = function linkResolver(doc) {
    // Route for blog posts
    if (doc.type === "lesson") {
        return "/rezepte/" + doc.uid
    }
    // Homepage route fallback
    return "/"
  }


